.rs-preview {
  position: absolute;
  left: 50%;
  top: 5px;
  transform: translate(-50%, 0);
  // right: 16px;
  // top: 80px;
  z-index: 10;
  width: 100px;
  height: 30px;
  color: #fff;
  // background-color: #fff;
  // border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  // box-shadow: 0 0 3px 0px #000;
  font-size: 13px;
  span {
    margin-right: 10px;
  }
  .icon {
    display: block;
    fill: currentColor; 
    width: 30px;
    height: 30px;
    overflow: hidden;
    cursor: pointer;
  }
}
