.rs-square-container {
  padding: 20px ;
  min-height: calc(100vh - 30px);
  background-color: #f0f9ff;
  display: flex;
}

.rs-square {
  width: 200px;
  padding: 10px;
  height: 311px;
  border-radius: 2px;
  // height: 300px;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
  .rs-square-bg {
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .rs-square-btn {
    display: none;
    // display: table;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50%;
    height: 25px;
    background-color: #00c091;
    border-radius: 6px;
    text-align: center;
    font-size: 13px;
    line-height: 25px;
    color: #ffffff;
    cursor: pointer;
    transform: translate(-50%, -50%);
    transition: all 0.3s;
  }
  &:hover {
    // background-color: #ddd;
    transform: translate3d(0, 10px, 0);
    box-shadow: 0 0 5px 1px #af9d9d;
    .rs-square-bg {
      display: block;
    }
    .rs-square-btn {
      display: block;
    }
  }
  img {
    width: 100%;
    
    // border: 1px solid #ddd;
  }
  .rs-userInfo {
    padding: 10px 0;
    font-size: 14px;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      color: #46505a;
    }
    img {
      width: 20px;
      border-radius: 40%;
    }
  }
}


.square-modal {
  display: flex;
  .square-modal-left {
    img {
      width: 300px;
    }
  }
  &-right {
    width: 250px;
    margin-left: 80px;
    position: relative;
    a {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
    .top-info-content {
      margin-left: 80px;
      .info-text {
        color: #999;
        font-size: 12px;
        display: block;
        margin: 8px 0 6px;
      }
    } 
    .top-list {
      .info-text {
        height: 60px;
        margin-top: 20px;
        display: block;
        border-bottom: 1px solid #e9e9e9;
        padding-bottom: 4px;
        display: flex;
        align-items: center;
        .icon {
          margin-right: 15px;
          vertical-align: middle;
          width: 26px;
          height: 26px;
          color: #999;
          fill: #999;
        }
        .text {
          font-size: 14px;
          vertical-align: middle;
          color: #999;
        }
        .value{
          margin-left: auto;
          font-size: 14px;
          vertical-align: middle;
          color: #999;
        }
      }
    }
  }
}

.square-footer {
  text-align: center;
  width: 100%;
}
