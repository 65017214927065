@barHeight: 30px;

.rs-header-bar {
  display: flex;
  height: @barHeight;
  background-color: #f0f9ff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
  align-items: center;
  padding: 0 30px 0 30px;
  justify-content: space-between;
  &__left {
    display: flex;
    align-items: center;
  }
  &__right {
    display: flex;
    justify-content: flex-end;
    .icon {
      fill: black;
    }
  }
  .rs-logo {
    margin-left: 20px;
    img {
      height: 20px;
      width: 20px;
      margin-right: 5px;
      border-radius: 4px;
      vertical-align: middle;
    }
  }
  a {
    color: #333;
    font-size: 14px;
    padding: 0px 10px;
    &:focus {
      color: #333;
      background: #e6f7ff;
    }
  }
  .rs-link {
    display: block;
    height: @barHeight;
    line-height: @barHeight;
  }
}


.rs-feed-group {
  background: #fff;
  box-shadow: 0 4px 12px rgb(0 0 0 / 20%);
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 16px;
  margin: 4px;
  &__wechat {
    flex-direction: column;
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 33px;
    .bg {
      width: 114px;
      height: 114px;
      background-size: 92px 92px;
      background-image: url(https://s3.qiufengh.com/muji/webchat-group.png);
      background-repeat: no-repeat;
      background-position: 50%;
      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 148px;
        top: -3px;
        background: hsla(0,0%,80%,.6);
        right: -17px;
      }
    }
  }
  &__qq {
    flex-direction: column;
    position: relative;
    display: flex;
    align-items: center;
    .bg {
      width: 114px;
      height: 114px;
      background-size: 92px 92px;
      background-image: url(https://s3.qiufengh.com/muji/qq-group.png);
      background-repeat: no-repeat;
      background-position: 50%;
    }
  }
  &__text {
    font-size: 12px;
    color: #000;
    font-weight: 500;
    margin-bottom: 12px;
    line-height: 20px;
  }
}

.template-wrapper {
  display: flex;
  .template {
    margin: 0 10px;
    cursor: pointer;
  }
  .active {
    box-shadow: 0px 0px 5px 3px #888080;
  }
  .template-img {
    width: 250px;
    height: 345px;
  }
  .template-title {
    text-align: center;
    margin: 5px 0;
  }
}

.uploadMd {
  display: none;
  cursor: pointer;
}


.rs-article-container {
  h2 {
    font-size: 18px;
    margin: 10px 0;
  }
  img {
    width: 60%;
  }
  li {
    margin-left: 20px;
    list-style-type: square;
  }
}
