.rs-color-wrapper {
  position: fixed;
  right: 20px;
  top: 100px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .rs-color-btn {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color: #fff;
    border: 2px solid #fff;
  }
}

