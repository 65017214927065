html, body {
  width: 100%;
  height: 100%;  
}

#root {
  width: 100%;
  height: 100%;
}

.rs-root {
  width: 100%;
  height: 100%;
}

.rs-body {
  width: 100%;
  height: 100%;
}

.h2_block .icon {
  fill: black !important;
}

@font-face {
  font-family: 'pingfang-sc';
  src: url('https://s3.qiufengh.com/muji/font/PingFang-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}


.break-line {
  display: block;
  width: 100%;
  height: 5px;
}

.mr20 {
  margin-right: 20px;
}

.btn {
  display: inline-block;
  padding: 0 20px;
  height: 32px;
  line-height: 30px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #999;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  box-sizing: border-box;
}

.btn-normal {
  color: #333;
  background-color: #ddd;
  border-radius: 40px;
}
